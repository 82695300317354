<template>
    <div>
        <!-- 主页 -->
        <keep-alive>
            <component :is="myComponent" :key="myComponent"></component>
        </keep-alive>
    </div>
</template>
<script>
export default {
    components: {
        index: () => import("./index.vue"),
        add: () => import("./add.vue"),
    },
    data() {
        return {
            currentComponent: "index",
        };
    },
    mounted() {},
    computed: {
        myComponent() {
            return this.$store.state.chooseModule.value ? this.$store.state.chooseModule.value : "index";
        },
    },
    methods: {},
};
</script>
<style scoped></style>
